<script>
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "ProjectShowTeams",
  setup() {
    const route = useRoute();
    const store = useStore();

    const team = reactive({
      filters: {
        query: ""
      }
    });
    const state = reactive({
      projectId: route.params.id,
      teams: []
    });

    store.dispatch("project/getTeams", { id: state.projectId }).then(r => {
      state.teams = r.data;
    });

    return {
      team,
      teams: computed(() => state.teams)
    };
  }
});
</script>

<template>
  <section class="space-y-4">
    <div class="relative">
      <svg
        width="20"
        height="20"
        fill="currentColor"
        class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        />
      </svg>
      <input
        v-model.lazy="team.filters.query"
        class="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10"
        type="text"
        aria-label="Filter teams"
        placeholder="Filter teams"
      >
    </div>
    <ul
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4"
    >
      <li class="hover:shadow-lg flex rounded-lg">
        <router-link
          :to="{ name: 'dashboard.team.create' }"
          class="hover:border-transparent hover:shadow-xs w-full flex items-center justify-center rounded-lg border-2 border-dashed border-gray-200 text-sm font-medium py-4 cursor-pointer"
        >
          Assign a team
        </router-link>
      </li>
      <li
        v-for="team in teams"
        :key="team.id"
      >
        <router-link
          :to="{ name: 'dashboard.team.show', params: { id: team.id } }"
        >
          <div
            class="group cursor-pointer rounded-lg p-4 border border-gray-200 hover:bg-light-blue-500 hover:border-transparent hover:shadow-lg"
          >
            <dl
              class="grid sm:block lg:grid xl:block grid-cols-2 grid-rows-2 items-center space-y-2"
            >
              <div class="flex space-y-1 space-x-3">
                <div class="w-12 h-12 relative image-fit">
                  <img
                    class="rounded-md"
                    rel="preload"
                    alt="Quant Lines"
                    :src="team.logo"
                  >
                </div>
                <div class="w-full">
                  <div>
                    <dt class="sr-only">
                      Title
                    </dt>
                    <dd
                      class="flex justify-between eading-6 font-medium text-base text-indigo-600 group-hover:text-white"
                    >
                      {{ team.name }}
                      <div
                        v-if="team.personal_team"
                        class="flex justify-center items-center bg-gray-200 w-6 h-6 rounded-full text-green-500"
                        title="Personal Team"
                      >
                        <i class="ri-star-fill" />
                      </div>
                    </dd>
                  </div>
                </div>
              </div>
              <div class="col-start-2 row-start-1 row-end-3">
                <dt class="sr-only">
                  Users
                </dt>
                <dd
                  class="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-2"
                >
                  <img
                    v-for="employee in team.employees"
                    :key="employee.id"
                    :src="employee.profile_photo"
                    :alt="employee.name"
                    :title="employee.name"
                    width="48"
                    height="48"
                    class="w-7 h-7 rounded-full bg-gray-100 border-2 border-white"
                    loading="lazy"
                  >
                </dd>
              </div>
            </dl>
          </div>
        </router-link>
      </li>
    </ul>
  </section>
</template>
